import React, { useState } from 'react';
import ReactPlayer from 'react-player'
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.module.css';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
    listingVideo,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;

  // video thumbnail
  const imageVariants = firstImage && firstImage.attributes.variants;
  const has4xVariant = imageVariants && imageVariants['landscape-crop4x'] !== undefined;
  const videoThumbnail = has4xVariant ? imageVariants['landscape-crop4x'].url : true;

  const [playing, setPlaying] = useState(false);
  const [playingVideoInCarousel, setPlayingVideoInCarousel] = useState(false);

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button
      className={css.viewPhotos}
      onClick={e => {
        setPlaying(false);
        handleViewPhotosClick(e)
      }}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  return (
    <div className={css.sectionImages}>
      <div className={css.threeToTwoWrapper}>
        {listingVideo !== null ? (
          <div className={css.playerWrapper}>
            {actionBar}
            <ReactPlayer
              className={css.player}
              playing={playing}
              controls
              onClickPreview={(e) => {
                e.preventDefault();
                setPlaying(!playing)
              }}
              url={listingVideo}
              light={videoThumbnail}
              width="100%"
              height="100%"
            />
            {viewPhotosButton}
          </div>
        ) : (
          <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
            {actionBar}
            <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={[
                'landscape-crop',
                'landscape-crop2x',
                'landscape-crop4x',
                'landscape-crop6x',
              ]}
            />
            {viewPhotosButton}
          </div>
        )}

      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={() => {
          setPlayingVideoInCarousel(false)
          onImageCarouselClose()
        }}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel
          listingVideo={listingVideo}
          playingVideoInCarousel={playingVideoInCarousel}
          setPlayingVideoInCarousel={setPlayingVideoInCarousel}
          images={listing.images}
          thumbnail={videoThumbnail}
          imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
        />
      </Modal>
    </div>
  );
};

export default SectionImages;
