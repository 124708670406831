import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { IconReviewStar, InlineTextButton, ListingDiscount } from '../../components';
import css from './ListingPage.module.css';
import SocialMediaLinksSection from './SocialMediaLinksSection';

const SEVEN_DAYS_PLUS = 'sevenDaysPlus'
const THIRTY_DAYS_PLUS = 'thirtyDaysPlus'

const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    richTitle,
    category,
    hostLink,
    showContactUser,
    onContactUser,
    typeOfListing,
    isServiceListing,
    publicData,
    price,
    intl,
    priceData,
  } = props;

  const discounts = publicData.discounts || {};
  const hasDiscount = Object.keys(discounts).length > 0;
  const hasMultipleDiscounts = Object.keys(discounts).length > 1;
  const percentageOf7Days = discounts[SEVEN_DAYS_PLUS]?.percent || null
  const percentageOf30Days = discounts[THIRTY_DAYS_PLUS]?.percent || null
  const avgReviewRating = publicData && parseFloat(publicData.avg_review_rating).toFixed(2) || 5;

  const unitTranslationKey = isServiceListing
    ? 'ListingPage.perGig'
    : 'ListingPage.perDay';

  const sevenDaysPlusDiscountTitle = intl.formatMessage({id: 'ListingPage.sevenDaysPlusDiscountTitle'});
  const thirtyDaysPlusDiscountTitle = intl.formatMessage({id: 'ListingPage.thirtyDaysPlusDiscountTitle'});

  const priceLayout = hasDiscount ? css.priceRowLayout : css.priceColumnLayout;
  const desktopPriceLayout = classNames(priceLayout);

  return (
    <div className={css.sectionHeading}>
      <div className={css.desktopPriceContainer}>
        <div className={desktopPriceLayout}>
          <div className={css.desktopPriceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          {hasDiscount ? (
            <span className={css.priceDivider}>/</span>
          ) : null}
          <div className={css.desktopPerUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>
        { hasDiscount ? (
          <div className={css.desktopDiscountWrapper}>
            { hasDiscount && percentageOf7Days ? (
              <ListingDiscount
                title={sevenDaysPlusDiscountTitle}
                price={price}
                percentage={percentageOf7Days}
                priceData={priceData}
                intl={intl}
                unitTranslationKey={unitTranslationKey}
                hasMultipleDiscounts={hasMultipleDiscounts}
              />
            ) : null }
            { hasDiscount && percentageOf30Days ? (
              <ListingDiscount
                title={thirtyDaysPlusDiscountTitle}
                price={price}
                percentage={percentageOf30Days}
                priceData={priceData}
                intl={intl}
                unitTranslationKey={unitTranslationKey}
              />
            ) : null }
          </div>
        ) : null }
      </div>

      <div className={css.heading}>
        <div className={css.titleWrapper}>
          <h1 className={css.title}>{richTitle}</h1>
          <div className={css.socialIconsWrapper}>
            <SocialMediaLinksSection publicData={publicData} />
          </div>
        </div>
        <div className={css.author}>
          <div>
            {category}
            {typeOfListing}
            <FormattedMessage id="ListingPage.hostedBy" values={{ name: hostLink }} />
            {showContactUser ? (
              <span className={css.contactWrapper}>
                <span className={css.separator}>•</span>
                <InlineTextButton
                  rootClassName={css.contactLink}
                  onClick={onContactUser}
                  enforcePagePreloadFor="SignupPage"
                >
                  <FormattedMessage id="ListingPage.contactUser" />
                </InlineTextButton>
              </span>
            ) : null}
          </div>
          <div className={css.starIconWrapper}>
            <span className={css.separator}>•</span>
            <IconReviewStar
              width={12}
              height={12}
              rootClassName={css.starIcon}
            />
            <span className={css.starText}>{avgReviewRating}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
