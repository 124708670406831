import React from 'react';
import { ExternalLink, IconSocialMediaFacebook, IconSocialMediaInstagram, IconSocialMediaLinkedin, IconSocialMediaTikTok } from '../../components';

import css from './ListingPage.module.css';

const SocialMediaLinksSection = ({publicData}) => {
   const facebook = publicData && publicData.facebook ? publicData.facebook : null;
  const instagram = publicData && publicData.instagram ? publicData.instagram : null;
  const linkedin = publicData && publicData.linkedin ? publicData.linkedin : null;
  const tiktok = publicData && publicData.tiktok ? publicData.tiktok : null;

  const fbLink = facebook ? (
    <ExternalLink
      key="linkToFacebook"
      href={facebook}
      className={css.icon}
      title='Go to facebook'
    >
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const instragramLink = instagram ? (
    <ExternalLink
      key="linkToInstagram"
      href={instagram}
      className={css.icon}
      title='Go to instagram'
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  const linkedinLink = linkedin ? (
    <ExternalLink
      key="linkToLinkedin"
      href={linkedin}
      className={css.icon}
      title='Go to linkedin'
    >
      <IconSocialMediaLinkedin />
    </ExternalLink>
  ) : null;

  const tiktokLink = tiktok ? (
    <ExternalLink
      key="linkToTiktok"
      href={tiktok}
      className={css.icon}
      title='Go to TikTok'
    >
      <IconSocialMediaTikTok />
    </ExternalLink>
  ) : null;

  return [fbLink, instragramLink, linkedinLink, tiktokLink].filter(v => v !== null);
}

export default SocialMediaLinksSection;
