import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,

} from "react-share";
import config from '../../config';
import css from './ListingPage.module.css';

const SectionShareOn = ({title, urlPathName, shareOnLabelTitle, shareButtonsTitle}) => {
  const url = `${config.canonicalRootURL}${urlPathName}`
  return (
    <div className={css.sectionShareOn}>
      <h2 className={css.shareOnHeading}>
        {shareOnLabelTitle}
      </h2>
      <div>
        <FacebookShareButton
          url={url}
          quote={shareButtonsTitle}
          style={{marginRight: "12px"}}
        >
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <TwitterShareButton
          url={url}
          title={shareButtonsTitle}
          style={{marginRight: "12px"}}
        >
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
        <TelegramShareButton
          url={url}
          title={shareButtonsTitle}
          style={{marginRight: "12px"}}
        >
          <TelegramIcon size={32} round={true} />
        </TelegramShareButton>
        <WhatsappShareButton
          url={url}
          title={shareButtonsTitle}
          style={{marginRight: "12px"}}
        >
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
        <EmailShareButton
          url={url}
          subject={shareButtonsTitle}
        >
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
      </div>
    </div>
  )
}

export default SectionShareOn;
